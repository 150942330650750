.admin-layout .page-content {
  margin-top: 0 !important;
  padding-top: 1rem !important;
}

.admin-layout .main-content {
  margin-top: 0 !important;
  /* Add padding to account for fixed navbar */
  padding-top: 60px;
}

/* Default for light mode */
.admin-navbar {
  background-color: #f8f9fa; /* Light background */
  border-bottom: 1px solid #e9ecef;
  margin-top: 0;
  padding: 0.5rem 0;
  position: fixed; /* Fix to the top */
  top: 70px; /* Position below the top navigation bar */
  left: 0;
  right: 0;
  z-index: 100; /* Ensure it's above content */
}

.admin-navbar .nav {
  border-bottom: none;
  padding-left: 240px; /* Match the navbar-brand-box-width from _variables-dark.scss */
}

@media (max-width: 992px) {
  .admin-navbar .nav {
    padding-left: 1rem;
  }
}

/* Dark mode override */
html[data-theme='dark'] .admin-navbar {
  background-color: #232038; /* Dark background */
  border-bottom: 1px solid #2c313a;
}

.admin-tab {
  border-color: transparent;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s;
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  outline: none;
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.admin-tab:focus {
  outline: none;
  box-shadow: none;
}

.admin-tab.active {
  color: #fff !important;
  background-color: #117CA1 !important;
}

/* Light mode tab styles */
html[data-theme='light'] .admin-tab:not(.active) {
  color: #424242;
  background-color: transparent;
}

html[data-theme='light'] .admin-tab:not(.active):hover {
  color: #117CA1;
  background-color: rgba(17, 124, 161, 0.1);
}

/* Dark mode tab styles */
html[data-theme='dark'] .admin-tab:not(.active) {
  color: #8a8a8d;
  background-color: transparent;
}

html[data-theme='dark'] .admin-tab:not(.active):hover {
  color: #fff;
  background-color: rgba(17, 124, 161, 0.5);
}

/* Fix for nav items */
.admin-navbar .nav-item {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

/* Light mode card styles */
.admin-card {
  background-color: #ffffff;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
  border-radius: 0.25rem;
  border: none;
}

.admin-card-header {
  background-color: #ffffff;
  border: 0;
  padding: 1rem 1.25rem;
}

.admin-card-title {
  color: #2A283B;
  margin-bottom: 0;
  font-weight: 500;
}

/* Dark mode card styles */
html[data-theme='dark'] .admin-card {
  background-color: #232038;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.2);
}

html[data-theme='dark'] .admin-card-header {
  background-color: #232038;
}

html[data-theme='dark'] .admin-card-title {
  color: #fff;
}

/* Light mode table styles */
.admin-table-header {
  background-color: #f8f9fa;
}

.admin-table-header th {
  color: #2A283B;
  font-weight: 500;
  border-bottom: 1px solid #e9ecef;
}

/* Dark mode table styles */
html[data-theme='dark'] .admin-table-header {
  background-color: #1c1a2e;
}

html[data-theme='dark'] .admin-table-header th {
  color: #ffffff;
  border-bottom: 1px solid #2c313a;
}

html[data-theme='dark'] .table {
  color: #e9ecef;
}

html[data-theme='dark'] .table td {
  border-top: 1px solid #2c313a;
} 